<script>
    import Fa from "svelte-fa/src/fa.svelte";
    import {
        faArrowDown,
        faArrowLeft,
        faArrowRight,
        faArrowUp,
        faArrowUpRightFromSquare
    } from "@fortawesome/free-solid-svg-icons";
    import { inertia } from '@inertiajs/svelte'


    export let properties
    export let subscriber
    export let name
    export let haircolors = []
    export let eyecolors = []
    export let ethnicities = []
    export let accessorObject
    export let editable = true

    function haircolor(id) {
        const color = haircolors.find(x => x.id === id);
        return color ? color.name : '';
    }

    function eyecolor(id) {
        const color = eyecolors.find(x => x.id === id);
        return color ? color.name : '';
    }

    function ethnicity(id) {
        const ethnicity = ethnicities.find(x => x.id === id);
        return ethnicity ? ethnicity.name : '';
    }

    function addToSelection() {
        accessorObject.toSelection(subscriber)
    }

    function removeFromSelection() {
        accessorObject.toPreselection(subscriber)
    }

    function subscriberName() {
        // compose name of firstname, preposition and lastname. Preposition is optional
        let name = subscriber.user.firstname;
        if (subscriber.user.preposition) {
            name += ' ' + subscriber.user.preposition;
        }
        name += ' ' + subscriber.user.lastname;
        return name;
    }

</script>

<style rel="stylesheet">
    @media(max-width: 375px) {
        table td {
            display: inline-block;
        }
    }
</style>

<div class="subscriber mb-3 md:mb-10">
    <div class="card w-full bg-neutral-200 shadow-md p-4 lg:p-10">
        <div class="card-title pb-3 items-start">
            {#if properties.includes('name')}
                <div class="flex flex-col ">
                    <h3 class="text-lg">{subscriberName()}</h3>
                    <span class="text-neutral-500 text-sm font-light">{subscriber.pivot.comment}</span>
                </div>
                <a use:inertia class="btn btn-link btn-xs" href="{window.location.href}/subscriber/{subscriber.slug}">Bekijk profiel
                    <Fa icon="{faArrowUpRightFromSquare}"></Fa>
                </a>
            {/if}
        </div>
        <div class="card-body p-0 flex flex-col md:flex-row gap-5 items-center">
            {#if properties.includes('photo') }
                <div class="avatar">
                    <div class="md:max-w-[250px] md:max-h-[250px] min-w-[150px] rounded-xl justify-center">
                        {#if subscriber.photos && (!subscriber.photos[0] || subscriber.photos[0]?.path == null)}
                            <img src="https://placehold.co/250x250" alt="{subscriber.name}"/>
                        {:else}
                            <img class="w-full object-cover"
                                 loading="eager"
                                 src="https://cma.benfcasting.nl/photos/images/subscriber/thumb{subscriber.photos[0]?.path ?? ''}"
                                 alt="{subscriberName()}"/>
                        {/if}
                    </div>
                </div>
            {/if}
            <div class="info">

                <table>
                    {#if properties.includes('id')}
                        <tr>
                            <td class="pr-2 font-bold">Id:</td>
                            <td>{subscriber.id}</td>
                        </tr>
                    {/if}
                    {#if properties.includes('email') }
                        <tr>
                            <td class="pr-2 font-bold">Email:</td>
                            <td>{subscriber.user.email}</td>
                        </tr>
                    {/if}
                    {#if properties.includes('address') }
                        <tr>
                            <td class="pr-2 font-bold">Straat:</td>
                            <td>{subscriber.street} {subscriber.street_number}{subscriber.street_number_add} </td>
                        </tr>
                    {/if}
                    {#if properties.includes('address') }
                        <tr>
                            <td class="pr-2 font-bold">Postcode & Plaats:</td>
                            <td>
                                {subscriber.zipcode}
                                {subscriber.city}
                            </td>
                        </tr>
                    {/if}
                    {#if properties.includes('phone') }
                        <tr>
                            <td class="pr-2 font-bold">Telefoon nummer:</td>
                            <td>{subscriber.phone}</td>
                        </tr>
                    {/if}
                    {#if properties.includes('sex') }
                        <tr>
                            <td class="pr-2 font-bold">Geslacht:</td>
                            <td>{subscriber.sex.toLowerCase() === 'f' ? 'Vrouw' : subscriber.sex.toLowerCase() === 'm' ? 'Man' : 'Anders'}</td>
                        </tr>
                    {/if}
                    {#if properties.includes('age') }
                        <tr>
                            <td class="pr-2 font-bold">Leeftijd:</td>
                            <td>{subscriber.age} ({subscriber.birthdate.substring(0, 10)})</td>
                        </tr>
                    {/if}
                    {#if properties.includes('eye_color') }
                        <tr>
                            <td class="pr-2 font-bold">Kleur ogen:</td>
                            <td>{eyecolor(subscriber.eye_color_id)}</td>
                        </tr>
                    {/if}
                    {#if properties.includes('hair_color') }
                        <tr>
                            <td class="pr-2 font-bold">Kleur haar:</td>
                            <td>{haircolor(subscriber.hair_color_id)}</td>
                        </tr>
                    {/if}
                    {#if properties.includes('ethnicity') }
                        <tr>
                            <td class="pr-2 font-bold">Ethniciteit:</td>
                            <td>{ethnicity(subscriber.ethnicity_id)}</td>
                        </tr>
                    {/if}
                    {#if properties.includes('height') }
                        <tr>
                            <td class="pr-2 font-bold">Lengte:</td>
                            <td>{subscriber.height} cm</td>
                        </tr>
                    {/if}
                    {#if properties.includes('weight') }
                        <tr>
                            <td class="pr-2 font-bold">Gewicht:</td>
                            <td>{subscriber.weight} kg</td>
                        </tr>
                    {/if}
                    {#if properties.includes('size_shoe') }
                        <tr>
                            <td class="pr-2 font-bold">Schoenmaat:</td>
                            <td>{subscriber.size_shoe}</td>
                        </tr>
                    {/if}
                    {#if properties.includes('size_dress') }
                        <tr>
                            <td class="pr-2 font-bold">Confectiemaat:</td>
                            <td>{subscriber.size_dress}</td>
                        </tr>
                    {/if}
                    {#if properties.includes('bsn') }
                        <tr>
                            <td class="pr-2 font-bold">BSN:</td>
                            <td>{subscriber.bsn}</td>
                        </tr>
                    {/if}
                </table>
            </div>
        </div>
        {#if editable}
            <div class="card-actions justify-center">

                {#if !subscriber.pivot.in_selection}
                    <button data-tip="Voeg deze figurant toe aan de selectie" class="btn btn-primary mt-5"
                            on:click={addToSelection}>Naar Selectie
                        <Fa class="md:hidden inline-block" icon="{faArrowDown}"></Fa>
                        <Fa class="hidden md:inline-block" icon="{faArrowRight}"></Fa>
                    </button>
                {:else}
                    <button data-tip="Haal deze figurant uit de selectie" class="btn btn-secondary mt-5"
                            on:click={removeFromSelection}>
                        <Fa class="md:hidden inline-block" icon="{faArrowUp}"></Fa>
                        <Fa class="hidden md:inline-block" icon="{faArrowLeft}"></Fa>
                        Uit Selectie
                    </button>
                {/if}
            </div>
        {/if}
    </div>
</div>
